const Icon = ({width, height}) => {

  return (
    <svg
      width={ width ? width : "33"}
      height={ height ? height : "8"}
      viewBox="0 0 33 8"
    >
      <path d="M28.9175 8L28.0603 7.14273L30.1821 5.02092H0V2.96742H30.1713L28.0603 0.857271L28.9175 0L32.9081 3.99058L32.8992 4.00045L32.9081 4.00942L28.9175 8Z" fill="#D02B48"/>
    </svg>
  );
};
export default Icon;
