import styled from 'styled-components';

export const TopLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #D02B48;
  margin-bottom: 5px;
`;

export const BottomLine = styled.div`
  height: 10px;
  width: 100%;
  background-color: #D02B48;
`;
