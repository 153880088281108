import React from 'react';
import Box from '@material-ui/core/Box';

import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import slide1 from '../../../src/img/slider/1.jpg';
import slide2 from '../../../src/img/slider/2.jpg';
import slide3 from '../../../src/img/slider/3.jpg';
import slide4 from '../../../src/img/slider/4.jpg';



import {Image} from "./styled";

const Fragment = ({text}) => {

  return(
    <>
    <SimpleReactLightbox>
      <SRLWrapper>
        <Box display="flex">
          <Box height='180px' overflow='hidden'>
            <Image src={slide1} />
          </Box>
          <Box height='180px' overflow='hidden'>
            <Image src={slide2} />
          </Box>
          <Box height='180px' overflow='hidden'>
            <Image src={slide3} />
          </Box>
          <Box height='180px' overflow='hidden'>
            <Image src={slide4} />
          </Box>
        </Box>
      </SRLWrapper>
    </SimpleReactLightbox>
    </>
  )

};

export default Fragment;
