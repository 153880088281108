import styled from 'styled-components';

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 2px;
  background-color: #555555;
  background-color: #FFFFFF;
  opacity: 0.1;
`;
