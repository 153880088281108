import Scroll from '../../components/Layout/HomeScroll'
import {Container,Row,Col} from '../../components/Layout/Grid';

import Icon from '../../components/Icons'
import Background from '../../components/Background'
import React from 'react';
import Slogan from '../../components/Slogan'
import Tabs from '../../components/Tabs'
import Description from '../../components/Text/Description'

import Phone from '../../components/Phone'

import Box from '@material-ui/core/Box';
import {Helmet} from "react-helmet";
import {
  Link,
} from "react-router-dom";

import {withRouter} from 'react-router'

import {
  WrapperLink
      } from './styled';

const Fragment = ({pb}) => {

const [tabState, setTabStateSt] = React.useState(0);

React.useEffect(()=>{
  let tm = localStorage.getItem("tab") ? localStorage.getItem("tab") : 0;
  console.log('tm',tm);
  setTabStateSt(parseInt(tm));
},[])

const setTabState = (val) => {
  setTabStateSt(val);
  localStorage.setItem("tab",val);
}

return (<>

<Helmet>
    <title>Бизнес Интеграция - Разработчик программного обеспечения Java, Spring, Android, iOS</title>
</Helmet>

<Scroll>
  <Container main pb='0px'>
    <Row>
      <Col xs12>
        <Box
          display='flex'
          justifyContent='space-between'
          flexDirection={{xs: 'column', md: 'row'}}
        >
          <Icon icon ='logo'/>

          <Box mt={{xs: '35px', md: '0'}}>
            <Tabs
              setTabState = {setTabState}
              tabState={tabState}
            />
          </Box>

        </Box>

        <WrapperLink>
          {tabState === 0 &&
          <>
            <Box
              display='flex'
              flexDirection='column'
              mt={{xs: '60px', md: '75px'}}
            >
              <Link to="/who">Кто мы</Link>
              <Link to="/our">Наш подход</Link>
              <Link to="/clients">Клиенты</Link>
              <Link to="/contacts">Контакты</Link>
            </Box>
            <Box
              width='364px'
              display={{xs: 'none', md: 'block'}}
              mt='20px'
            >
              <Description text='В своей деятельности мы опираемся на доверие, порядочность и профессионализм наших сотрудников'/>
            </Box>
          </>
          }
        {tabState === 1 &&
        <>
          <Box
            display='flex'
            flexDirection='column'
            mt={{xs: '60px', md: '75px'}}
          >
            <Link to="/people">Ценности</Link>
            <Link to="/vacancies">Вакансии</Link>
            <Link to="/contacts">Контакты</Link>
          </Box>
          <Box
            width='364px'
            display={{xs: 'none', md: 'block'}}
            mt='20px'
          >
            <Description text='В нашей деятельности мы опираемся на доверие, порядочность и профессионализм наших сотрудников'/>
          </Box>
        </>
        }
        </WrapperLink>

      </Col>

      <Col xs12>
        <Slogan />
      </Col>
    </Row>

    <Phone />

  </Container>
</Scroll>

<Background/>

</>)

};

export default withRouter(Fragment);
