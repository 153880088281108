import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const Title = styled.div`
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  ${device.xs} {
    font-size: 50px;
  }
`;
