import React from 'react';

import {FirstWord} from "./styled";

const Fragment = ({text}) => {

  return(
    <>
      <FirstWord>{text}</FirstWord>
    </>
  )

};

export default Fragment;
