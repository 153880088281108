import {Container,Row,Col} from "../Layout/Grid";
import React from 'react';
import Icon from '../Icons'
import Title from '../Text/Title'

import Box from '@material-ui/core/Box';



import {
  Link,
} from "react-router-dom";

import {Wrapper,Line} from "./styled";


const Fragment = ({text, pb}) => {



  return(
  <>

    <Container main pb='0'>
      <Row>
        <Col xs12>
          <Wrapper>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb='20px'
            >
              <Title text={text}/>
              <Link to="/">
                <Icon icon ='close'/>
              </Link>
            </Box>

            <Line />
          </Wrapper>
        </Col>
      </Row>
    </Container>

  </>
  )

};

export default Fragment;
