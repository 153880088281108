import styled from 'styled-components';

import AccordionMaterial from '@material-ui/core/Accordion';
import AccordionSummaryMaterial from '@material-ui/core/AccordionSummary';
import AccordionDetailsMaterial from '@material-ui/core/AccordionDetails';
import ExpandMoreIconMaterial from '@material-ui/icons/ExpandMore';

export const Accordion = styled(AccordionMaterial)`
  && {
    margin: 0 !important;
    background: transparent;
    border-radius: 0;
    &:first-child{
      border-radius: 0;
    }
    &:last-child{
      border-radius: 0;
      .MuiAccordionDetails-root {
        border-bottom: 1px solid #d4d4d4;
      }
    }
  }

`;

export const AccordionSummary = styled(AccordionSummaryMaterial)`
  && {
    border-top: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
  }
`;

export const AccordionDetails = styled(AccordionDetailsMaterial)`
  && {
    /* border-top: 1px solid #d4d4d4; */
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
  }
`;

export const ExpandMoreIcon = styled(ExpandMoreIconMaterial)`
  && {
    fill: #d4d4d4;
  }
`;
