import {Col} from '../Layout/Grid';
import Title from "../Text/Title";
import Subtitle from "../Text/Subtitle";
import Description from "../Text/Description";

import React from 'react';
import Box from '@material-ui/core/Box';


import {Line} from "./styled";

const Fragment = () => {

  return(
    <>
    <Col xs12 >
    <Box mt={{ xs: '30px', lg: '30px' }}/>

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mb='20px'
      >
        <Title text='ООО «БИЗНЕС ИНТЕГРАЦИЯ»' />
      </Box>

      <Line />


    </Col>

    <Col xs12 md6 >
      <Box mt={{ xs: '40px', lg: '30px' }}/>
      <Subtitle text='Юридический адрес' />
      <Description text='
      <p>350049, г. Краснодар, ул. Красных партизан, дом 226, эт.2, офис 207</p>
      <ul>
        <li>ИНН&nbsp;2308267610</li>
        <li>КПП&nbsp;230801001</li>
        <li>ОГРН&nbsp;1192375056895</li>
      </ul>
      '/>
    </Col>

    <Col xs12 md6 >
      <Box mt={{ xs: '30px', lg: '30px' }}/>
      <Subtitle text='Расчётный счёт' />
      <Description text='
        <p>В банке ПАО Сбербанк</p>
        <ul>
          <li>Р/с 40702810538000028409</li>
          <li>К/с 30101810400000000225</li>
          <li>БИК 044525225</li>
        </ul>
      '/>
    </Col>

    </>
  )

};

export default Fragment;
