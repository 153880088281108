import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const Description = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: ${({center}) => center ? 'center' : ''};
  opacity: ${({opacity}) => opacity ? opacity : '1' };
  font-weight: ${({fw}) => fw ? fw : '500' };
  ${device.xs} {
    font-size: 12px;
    line-height: 20px;
    text-align: left;
  }
  ul {
    list-style: none;
  }
  ul li {
    display: block;
    position: relative;
    margin-bottom: 3px;
    margin-left: -5px;
  }
  ul li:before {
      content:  "—";
      position: absolute;
      left: -35px;
      color: #8F8F8F;
  }
`;
