import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const Submit = styled.div`
  display: block;
  width: max-content;
  background: #D02B48;
  padding: 20px 30px 10px 30px;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
  ${device.xs} {
    width: auto;
    font-size: 30px;
    line-height: 32px;
    padding: 17px 30px 10px 30px;

  }
`;
