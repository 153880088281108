import Logo from "./logo";
import Sublogo from "./sublogo";
import Arrow from "./arrow";
import Phone from "./phone";
import Close from "./close";
import More from "./more";
import Slash from "./slash";

const Icon = ({ icon, width, height}) => {

  const list = {
    logo: <Logo/>,
    sublogo: <Sublogo/>,
    arrow: <Arrow/>,
    phone: <Phone/>,
    close: <Close width={width} height={height} />,
    more: <More/>,
    slash: <Slash/>,
  }

  return (
    <>
      {list[icon]}
    </>
  );
}

export default Icon;
