import React from 'react';
import Box from '@material-ui/core/Box';

import {File,Notice,FileInputStyled} from "./styled";




const Fragment = () => {

  const [nameFile, setNameFile] = React.useState('Файл не выбран')

  const fileUpload = async (e) => {
    if(e && e.target.files[0]) setNameFile(e.target.files[0].name)
  }

  return(
  <>
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems={{ xs: 'stretch', md: 'flex-end' }}
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <File>Прикрепить резюме/портфолио</File>
      <Notice>{nameFile}</Notice>
      <FileInputStyled
        type="file"
        name="file"
        onChange={fileUpload}/>
    </Box>
  </>
  )

};

export default Fragment;
