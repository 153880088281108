import React from 'react';
import {Container} from '../Layout/Grid';
import Box from '@material-ui/core/Box';

import {Svg1,Svg2,Svg3,Svg4,Svg6} from "./styled";

import bg1 from '../../img/bg/1.svg';
import bg2 from '../../img/bg/2.svg';
import bg3 from '../../img/bg/3.svg';
import bg4 from '../../img/bg/4.svg';
import bg6 from '../../img/bg/5.svg';

const Fragment = () => {

  return(
    <>
      <Box
        position='absolute'
        display={{xs: 'block', md: 'block'}}
        top='0'
        left={{xs: '1000px', md: '1350px'}}
        width='100%'
        zIndex='-10'
      >
        <Container main>
          <Svg6 src={bg6}/>
          <Svg1 src={bg2}/>
          <Svg2 src={bg1}/>
          <Svg3 src={bg3}/>
          <Svg4 src={bg4}/>

        </Container>
      </Box>

    </>
  )

};

export default Fragment;
