import styled from 'styled-components';
import { device } from '../Layout/Grid';

import LinearProgressMaterial from '@material-ui/core/LinearProgress';

export const LinearProgress = styled(LinearProgressMaterial)`
  && {
    display: block;
    background-color: #E8E8E8;
    height: 30px;
    border-radius: 2px;
    .MuiLinearProgress-barColorPrimary {
      background-color: ${({bgcolor}) => bgcolor ? bgcolor : '#5CB85C' };
    }
    ${device.xs} {
      height: 15px;
    }
  }
`;
