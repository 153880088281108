import Scroll from '../../components/Layout/InnerScroll'
import {Container,Row,Col} from '../../components/Layout/Grid';

import Icon from '../../components/Icons'
import Header from "../../components/Header";
import Subtitle from "../../components/Text/Subtitle";
import Description from "../../components/Text/Description";
import Lead from "../../components/Text/Lead";
import FirstWord from "../../components/Text/FirstWord";
import MenuBottom from "../../components/Menu/DesktopBottom";
import Line from "../../components/Line";
import Logo from "../../components/Logo";
import {Helmet} from "react-helmet";
import Box from '@material-ui/core/Box';


const Fragment = () => {

return (<>
<Helmet>
  <title>Ценности в командной работе - порядочность, доверие, саморазвитие</title>
  <meta name="description" content="Наша команда состоит из ожесточенно спорящих по поводу решения людей, но действующих единой командой при его осуществлении, не взирая на личные амбиции" />
</Helmet>

<Header text='Ценности'/>

<Scroll>
  <Container>
    <Row>
      <Col xs12 md6>
        <Box position='relative'>
          <Box
            position='absolute'
            left='-77px'
            display={{ xs: 'none', lg: 'block' }}
          >
            <Icon icon ='more'/>
          </Box>
          <Subtitle text='
            В&nbsp;своей деятельности мы&nbsp;опираемся на&nbsp;доверие, порядочность и&nbsp;профессионализм наших сотрудников, тех, кто интуитивно понимает, что не&nbsp;может следовать классическому сценарию в&nbsp;многочисленных компаниях: пришёл, подсидел кого-то, поднялся на&nbsp;чужих костях, обманул, стал начальником.'/>
          </Box>
        <Box mb={{ xs: '20px', lg: '30px' }} />
      </Col>

      <Col xs12 md6>
        <Description text='
          <p><span style="color: #999999;">У нас </span>нет начальников<span style="color: #999999;">, но есть здравый смысл и своя голова во всем, что мы делаем.</span></p>
        '/>
      </Col>

      <Col xs12 md6>
        <Box mb={{ xs: '20px', lg: '30px' }}>
          <Line />
        </Box>
        <Box display='flex'>
          <Box m='7px 38px 0 0'>
            <FirstWord text='Мы' />
          </Box>
          <Description text='создаем команду, состоящую из&nbsp;ожесточенно спорящих по&nbsp;поводу решения людей, но&nbsp;действующих единой командой при его осуществлении, невзирая на&nbsp;личные амбиции.'/>
        </Box>
        <Box my={{ xs: '20px', lg: '30px' }}>
          <Line />
        </Box>
      </Col>

      <Col xs12 md6 >
        <Lead text='> 100500' />
        <Description text='
          <p><span style="color: #999999;">Мы&nbsp;никогда не&nbsp;обсуждаем с&nbsp;другими членами команды свою или чужую зарплату.</span></p>
        '/>
        <Box mb={{ xs: '20px', lg: '30px' }}/>
      </Col>

      <Col xs12 md6>
        <Description text='
          <p>Мы&nbsp;всегда рады, когда наши сотрудники уровновешены&nbsp;&mdash; не&nbsp;только работают, но&nbsp;и&nbsp;проводят время с&nbsp;семьей, отдыхают, следят за&nbsp;своим здоровьем, саморазвиваются.</p>
          <p>Члены нашей команды делятся друг с&nbsp;другом опытом, подлердивая и&nbsp;развивая наставничество в&nbsp;любом его проявлении.</p>'
        />
      </Col>


      <Col xs12 md6>
        <Description text='
          <p><span style="color: #999999;">Подходит ли человек в нашу команду — скорее вопрос его </span>порядочности<span style="color: #999999;">, </span>характера <span style="color: #999999;">и </span>ценностей<span style="color: #999999;">, а не знаний, опыта или образования.</span></p>
        '/>
      </Col>

      <MenuBottom/>

    </Row>
  </Container>


</Scroll>

<Logo />



</>)

};

export default Fragment;
