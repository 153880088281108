import Scroll from '../../components/Layout/InnerScroll'
import {Container,Row,Col} from '../../components/Layout/Grid';

import Icon from '../../components/Icons'
import Header from "../../components/Header";
import Subtitle from "../../components/Text/Subtitle";
import Description from "../../components/Text/Description";
import FirstWord from "../../components/Text/FirstWord";
import Separator from "../../components/Separator";
import Accordion from "../../components/Accordion";
import Logo from "../../components/Logo";
import MenuBottom from "../../components/Menu/DesktopBottom";
import {Helmet} from "react-helmet";
import Box from '@material-ui/core/Box';


const Fragment = () => {

return (<>

<Helmet>
  <title>Работа и управление проектами по Agile, SCRUM, LeSS, LeSS Huge</title>
  <meta name="description" content="Мы управляем проектами, ведём в командах разработку по Agile, SCRUM, Kanban, LeSS, LeSS Huge методологиям, а также классическим проектным технологиям PMBoK" />
</Helmet>

<Header text='Наш подход'/>

<Scroll>
  <Container>
    <Row>
      <Col xs12 >
        <Box position='relative'>

          <Box
            position='absolute'
            left='-77px'
            display={{ xs: 'none', md: 'block' }}
          >
            <Icon icon ='more'/>
          </Box>


          <Subtitle text='Принципы совместной работы'/>

          <Description text='
            <p><span style="color: #999999;">На протяжении всего проекта участники совместной команды (аналитики, консультанты, разработчики, тестировщики и представители бизнеса) ежедневно:</span></p>
            <ul>
              <li>работают вместе<span style="color: #999999;">, опираясь на доверие и профессионализм друг друга; </span></li>
              <li><span style="color: #999999;">активно предлагают и обсуждают </span>продуктовые <span style="color: #999999;">и технические решения;</span></li>
              <li><span style="color: #999999;">действуют </span>единой командой<span style="color: #999999;">, не взирая на личные амбиции;</span></li>
              <li><span style="color: #999999;">делятся опытом, а также&nbsp;информацией, необходимой для решения задач;</span></li>
              <li><span style="color: #999999;">анализируют возможные способы улучшения </span>эффективности<span style="color: #999999;">, чтобы совершенствовать стиль своей работы.</span></li>
            </ul>
          '/>

          <Box mb={{ xs: '40px', lg: '50px' }}/>

          <Subtitle text='Каждый проект уникальный, но'/>

          <Description text='
            <p>Мы&nbsp;всегда предлагаем клиенту именно тот подход, который гарантирует решение его задач для обеспечения конкурентного преимущества. Для этого в&nbsp;своей работе мы&nbsp;используем два разных по&nbsp;своей природе подхода:</p>
          '/>

        </Box>
        <Box mb={{ xs: '40px', lg: '50px' }}/>

      </Col>

      <Col xs12 md6>

        <Box
          display='flex'
          flexDirection={{ xs: 'column', lg: 'row' }}
          mb={{ xs: '30px', lg: '0' }}
        >
          <Box m='7px 38px 0 0'>
            <FirstWord text='AGILE'/>
          </Box>
          <Box maxWidth='400px'>
            <Description text='гибкая методология разработки программного обеспечения'/>
          </Box>
        </Box>

      </Col>

      <Col xs12 md6>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', lg: 'row' }}
        >
          <Box m='7px 38px 0 0'>
            <FirstWord text='WATERFALL'/>
          </Box>
          <Box maxWidth='400px'>
            <Description text='каскадная модель разработки ПО&nbsp;с&nbsp;жесткой последовательностью выполнения этапов'/>
          </Box>
        </Box>
      </Col>

      <Col xs12>
        <Box my={{ xs: '40px', lg: '50px' }}>
          <Separator/>
        </Box>
      </Col>

      <Col xs12 md6>
        <Box my={{ xs: '30px', lg: '30px' }}>
          <Subtitle text='Ценности при работе в Agile'/>
          <Description text='<p>Не&nbsp;отрицая важности того, что внутри, мы&nbsp;<nobr>всё-таки</nobr> больше ценим то, что стоит в&nbsp;топиках ниже:</p> '/>
        </Box>

      </Col>

      <Col xs12 md6 />

      <Col xs12 md6>
          <Accordion />
      </Col>

      <Col xs12 md6 >
        <Description text='

        <p>Когда рекомендуется применять Agile?&nbsp;</p>
        <p><span style="color: #999999;">В трёх случаях: </span></p>
        <ul>
          <li>
              <p><span style="color: #999999;">при итерационном </span>создании <span style="color: #999999;">программного продукта с нуля;</span></p>
          </li>
          <li><span style="color: #999999;">при </span>поддержке <span style="color: #999999;">и </span>развитии <span style="color: #999999;">продукта;</span></li>
          <li>
              <p>
                  <span style="color: #999999;">когда функциональные требования пользователей детально не прописаны, </span>постоянно меняются
                  <span style="color: #999999;"> по причине их сложных итерационных&nbsp; доработок, требующих частичную апробацию на практике с применением принципа «малых шагов».</span>
              </p>
          </li>
        </ul>

        '/>
      </Col>

      <Col xs12 md6>
        <Box my={{ xs: '30px', lg: '30px' }}>
          <Subtitle text='Основные принципы Agile'/>
        </Box>
      </Col>

      <Col xs12 md6 />

      <Col xs12 md6>
        <Accordion />
      </Col>

      <Col xs12 md6>
        <Description text='
          <p><span style="color: #999999;">Agile-процессы позволяют использовать изменения для обеспечения заказчику </span>конкурентного преимущества<span style="color: #999999;">.</span></p>
        '/>

        <Box
          border='1px solid #D02B48'
          p='10px 30px'
          my={{ xs: '30px', lg: '30px' }}
        >
          <Description text='
            <p><span style="color: #999999;">Изменения требований в <em>Agile-проектах</em> реализуются за меньшую цену из-за частых инкрементов.</span></p>
          '/>
        </Box>

      </Col>

      <Col xs12 md6>
        <Box my={{ xs: '30px', lg: '30px' }}>

          <Subtitle text='Наши Agile-церемонии начинаются с начального наполнения продуктового бэклога, с первичного создания набора задач, что нужно выполнить для реализации идеи продукта'/>
          <Description text='
            <p>Для чего это нужно? В начале проекта у нашего клиента обычно есть лишь идея продукта с помощью которого он хочет «захватить мир». Но так как это завоевание происходит с помощью принесения ценности людям, то эти <strong><span style="color: #d02b48;">ценности необходимо грамотно сформулировать</span></strong> для разных групп пользователей через простую формулу пользовательских историй или&nbsp;user-stories:</p>
            <p style="text-align: center;"><em>Как &lt;роль&gt;, я хочу делать &lt;это&gt;, чтобы &lt;достигать цели&gt;</em></p>
          '/>
          </Box>
      </Col>

      <Col xs12 md6>
        <Description text='
          <p><span style="color: #999999;">Мы проводим серию встреч с представителями бизнеса клиента, чтобы помочь им структурировать свои продуктовые задачи, а также наполнить общий </span>бэклог <span style="color: #999999;">проекта первыми задачами и пользовательскими историями (</span>юзерсториз<span style="color: #999999;">).</span></p>

          <p><span style="color: #999999;">Примерное </span>распределение юзерсториз&nbsp;по релизам<span style="color: #999999;"> происходит как в начале проекта, так и по мере их постоянного поступления от бизнес-заказчиков и описания по формуле.</span></p>
        '/>
      </Col>

      <Col xs12 md6 >
        <Box my={{ xs: '30px', lg: '30px' }}>
          <Subtitle text='Далее происходит груминг бэклога,&nbsp; включающий в себя приоритезацию и оценку всей командой&nbsp; сложности выполнения его задач с целью дальнейшего планирования первого или очередного спринта'/>
        </Box>
      </Col>

      <Col xs12 md6 >
        <Description text='
          <p>Планирование спринта<span style="color: #999999;"> происходит в начале первой его недели. Совместной командой выбираются заранее оцененные по сложности задачи (прошедшие этап </span>груминг<span style="color: #999999;">а), которые будут выполнены за планируемый спринт (обычно две недели). Таким образом описывается </span>измеримый результат<span style="color: #999999;">, что будет в конце спринта, а также формулируется </span>цель спринта<span style="color: #999999;">, которую знает каждый член команды.</span></p>
        '/>
      </Col>

      <Col xs12 md6 >
        <Box
          display='flex'
          flexDirection={{ xs: 'column', lg: 'row' }}
          my={{ xs: '30px', lg: '30px' }}
        >
          <Box m='7px 38px 0 0'>
            <FirstWord text='2 НЕДЕЛИ'/>
          </Box>
          <Box maxWidth='400px'>
            <Description text='обычная длительность спринта'/>
          </Box>
        </Box>
      </Col>

      <Col xs12 md6 >

      </Col>

      <Col xs12 md6 >
        <Description text='
          <p>Далее, ежедневно, проводятся&nbsp; утренние стендапы (короткие встречи всей команды) на которых каждый член команды отвечает на три простых вопроса:</p>
          <ul>
              <li>Что было сделано за&nbsp;<strong>вчера</strong>?</li>
              <li>Что будет сделано <strong>сегодня</strong>?&nbsp;</li>
              <li>В чём ему нужна <strong>помощь</strong> и какие есть проблемы, мешающие завершить текущие задачи?</li>
          </ul>
          <p>После стендапа команда расходится выполнять свои задачи.&nbsp;</p>
        '/>
      </Col>

      <Col xs12 md6 >
        <Description text='
          <p>Стендап <span style="color: #999999;">или daily stand up обычно проводится утром и длится от 10 до 20 минут. Он сосредоточен на людях, чтобы вовремя отследить будут ли выполнены командой все задачи спринта, </span>выявить как можно раньше причины<span style="color: #999999;"> почему они не смогут быть выполнены.</span></p>
          <p><span style="color: #999999;">Рассказывая про задачи на сегодня, каждый даёт обещание команде выполнить </span>конкретные задачи<span style="color: #999999;"> до конца дня.</span></p>
        '/>
      </Col>

      <Col xs12 md6 >
        <Description text='
          <p>В течение хода спринта, при необходимости, всей командой может проводиться:</p>
          <ul>
            <li>описание,</li>
            <li>разбор,</li>
            <li>оценка новых, поступивших от бизнеса задач (груминг бэклога), а также их приоритезация в общем списке.</li>
          </ul>
        '/>
      </Col>

      <Col xs12 md6 />

      <Col xs12 md6 >
        <Box my={{ xs: '30px', lg: '30px' }}>
          <Subtitle text='Ближе к окончанию спринта, в конце второй его недели, проводится&nbsp;демонстрация измеримых запланированных результатов'/>
        </Box>

        <Description text='
          <p>После завершения <b>демо </b>проводится оценка участия каждого члена команды в достижении цели спринта и <b>ретроспектива </b>с целью осознания трех вещей:</p>
          <ol>
            <li>Какой положительный опыт был получен командой за прошедший спринт?</li>
            <li>Что надо поправить в будущем, чтобы улучшить продуктивность команды?</li>
            <li>Какой должен быть action-план для совершенствования работы совместной команды на ближайший спринт-два или три.</li>
          </ol>
        '/>

      </Col>

      <Col xs12 md6 >
        <Description text='
          <p><span style="color: #999999;">Когда проводится </span>ретроспектива <span style="color: #999999;">мы не приходим к команде с готовыми решениями, чтобы избежать синдрома чужой разработки (<em>NIH-синдром, от англ. «not invented here» — изобретено не нами</em>). Для этого команда сама находит правильное выстраданное решение, а не принимает навязанное сверху со всеми «вытекающими».</span></p>
        '/>
      </Col>

      <Col xs12 >
        <Box my={{ xs: '30px', lg: '30px' }}>
          <Separator/>
        </Box>
      </Col>


      <MenuBottom/>

    </Row>
  </Container>
</Scroll>

<Logo />

</>)

};

export default Fragment;
