import React from 'react';

import {Description} from "./styled";



const Fragment = ({ text, center = false, opacity,fw }) => {

  //console.log('text',text);

  return(

<>
    <Description
      dangerouslySetInnerHTML={{ __html: text }}
      center = {center}
      opacity = {opacity}
      fw = {fw}
    />
</>
  )

};

export default Fragment;
