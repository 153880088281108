import './App.css';
import Home from './pages/home'
import Our from './pages/our'
import Who from './pages/who'
import Clients from './pages/clients'
import People from './pages/people'
import Vacancies from './pages/vacancies'
import Contacts from './pages/contacts'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
    <div className="App">

       <Switch>
          <Route path="/our">
            <Our />
          </Route>
          <Route path="/who">
            <Who />
          </Route>
          <Route path="/clients">
            <Clients />
          </Route>
          <Route path="/people">
            <People />
          </Route>
          <Route path="/vacancies">
            <Vacancies />
          </Route>
          <Route path="/contacts">
            <Contacts />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>

    </div>
    </Router>
  );
}

export default App;
