import React from 'react';

import Box from '@material-ui/core/Box';


import
  {LinearProgress}
    from "./styled";


function LinearProgressWithLabel(props) {
  return (
    <>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </>
  );
}


export default function LinearWithValueLabel({scrollTop, bgcolor, maxProgress = 80, speed = 10}) {

  const [progress, setProgress] = React.useState(0);
  const [timer, setTimer] = React.useState();
  const [isWork, setIsWork] = React.useState(false);

  React.useEffect(() => {
    if(scrollTop > 250 && !timer && !isWork){
      let timer = setInterval(() => {

        //let tm = progress
        setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 5));
      }, speed);
      setTimer(timer);
    }
    /*return () => {
      clearInterval(timer);
    };*/
  }, [scrollTop]);

  React.useEffect(() => {

    if(progress > maxProgress){
      console.log('clearInterval',progress,timer);
      clearInterval(timer);
      setIsWork(true);
    }
  },[progress]);

  return (

      <LinearProgressWithLabel value={progress} bgcolor = {bgcolor}/>

  );
}
