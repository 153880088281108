import React from 'react';
import MuiThemeProvider from '../MuiThemeProvider'

import {
  Scroll
      } from './styled';

const Fragment = ({children}) => {

  return(

  <MuiThemeProvider>
    <Scroll>
      {children}
    </Scroll>
  </MuiThemeProvider>

  )
};

export default Fragment;
