import React from 'react';

import {Lead} from "./styled";



const Fragment = ({text}) => {

  return(

<>
    <Lead dangerouslySetInnerHTML={{ __html: text }}/>
</>
  )

};

export default Fragment;
