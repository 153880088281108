import React from 'react';

import {FormItem} from "./styled";

const Fragment = ({label,placeholder}) => {

  return(

  <>

    <FormItem>
      <label>{label}</label>
      <textarea name="comment" class="comment" rows="4">
      </textarea>
    </FormItem>

  </>
  )

};

export default Fragment;
