import React from 'react';

import Input from "../Input";
import Select from "../Select";
import Textarea from "../Textarea";
import File from "../File";
import Submit from "../Submit";
import Box from '@material-ui/core/Box';
import Line from "../../Line";
import axios from 'axios';
const Fragment = ({reloadvak}) => {

  const submit = async () => {

    const formData = new FormData(document.querySelector('form'));
    /*for (var pair of formData.entries()) {
       console.log(pair[0] + ': ' + pair[1]);
    }*/

    await axios.post(
      `/api/send`,
      formData,
    );

    reloadvak();

  }

  return(

  <>

    <form>
      <Input label='Фамилия Имя Отчество' name="fio"/>
      <Input label='Email' name="email"/>
      <Input label='Телефон' placeholder='+7 (999) 999 99 99' name="phone"/>
      <Select label='Интересующее место работы' name="city"/>
      <Textarea label='Комментарий, сопроводительное письмо'/>
      <File/>
      <Box my='40px'>
      <Line />
      </Box>
      <Submit handler={submit}/>
    </form>

  </>
  )

};

export default Fragment;
