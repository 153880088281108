const Icon = () => {

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" >
    <circle cx="30" cy="30" r="30" fill="#D02B48"/>
    <path d="M39.2074 34.8351C37.6594 33.5111 36.0884 32.7091 34.5594 34.0311L33.6464 34.8301C32.9784 35.4101 31.7364 38.1201 26.9344 32.5961C22.1334 27.0791 24.9904 26.2201 25.6594 25.6451L26.5774 24.8451C28.0984 23.5201 27.5244 21.8521 26.4274 20.1351L25.7654 19.0951C24.6634 17.3821 23.4634 16.2571 21.9384 17.5801L21.1144 18.3001C20.4404 18.7911 18.5564 20.3871 18.0994 23.4191C17.5494 27.0571 19.2844 31.2231 23.2594 35.7941C27.2294 40.3671 31.1164 42.6641 34.7984 42.6241C37.8584 42.5911 39.7064 40.9491 40.2844 40.3521L41.1114 39.6311C42.6324 38.3091 41.6874 36.9631 40.1384 35.6361L39.2074 34.8351Z" fill="white"/>
    </svg>
  );
};
export default Icon;
