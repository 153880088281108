import React from 'react';

import {Subtitle} from "./styled";

const Fragment = ({text, lh, color}) => {

  return(
    <>
      <Subtitle
        dangerouslySetInnerHTML={{ __html: text }}
        lh = {lh}
        color = {color}
      />
    </>
  )

};

export default Fragment;
