import React from 'react';
import {FormItem} from "./styled";

const Fragment = ({label,placeholder,name}) => {

  return(

  <>

    <FormItem>
      <label>{label}</label>
      <input
        name={name}
        type="text"
        placeholder={placeholder}
      />
    </FormItem>

  </>
  )

};

export default Fragment;
