import React from 'react';

import {TopLine, BottomLine} from "./styled";

const Fragment = () => {

  return(
    <>
      <TopLine/>
      <BottomLine/>
    </>
  )

};

export default Fragment;
