import React from 'react';
import Description from '../Text/Description'


import
  {Accordion,AccordionSummary,AccordionDetails,ExpandMoreIcon}
    from "./styled";


const Fragment = () => {



  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Description fw='700' text='Люди и взаимодействие' />
        </AccordionSummary>
        <AccordionDetails>
          <Description text='важнее процессов и инструментов.' />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Description fw='700' text='Работающий продукт'/>
        </AccordionSummary>
        <AccordionDetails>
          <Description text='важнее исчерпывающей документации.' />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Description fw='700' text='Сотрудничество с клиентом' />
        </AccordionSummary>
        <AccordionDetails>
          <Description text='важнее согласования условий контракта.' />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Description fw='700' text='Готовность к изменениям' />
        </AccordionSummary>
        <AccordionDetails>
          <Description text='важнее следования первоначальному плану.' />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Fragment;
