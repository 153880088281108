import Scroll from '../../components/Layout/InnerScroll'
import {Container,Row,Col} from '../../components/Layout/Grid';

import Icon from '../../components/Icons'
import Header from "../../components/Header";
import Subtitle from "../../components/Text/Subtitle";
import Description from "../../components/Text/Description";
import MenuBottom from "../../components/Menu/DesktopBottom";
import Logo from "../../components/Logo";

import {Helmet} from "react-helmet";
import Box from '@material-ui/core/Box';

import sberlog from '../../img/clients/1.png';
import korus from '../../img/clients/2.png';
import mfm from '../../img/clients/3.png';
import sber from '../../img/clients/4.png';
import doc from '../../img/clients/5.png';

import {
  Img
      } from './styled';


const Fragment = () => {

return (<>
<Helmet>
  <title>Клиенты - разработка на java, мобильная разработка на android и ios</title>
  <meta name="description" content="Компании, кому мы по нормальным ценам делали разработку на java и 1С, а стоимость мобильной разработки на android и ios была в согласованном бюджете" />
</Helmet>
<Header text='Клиенты'/>

<Scroll>
  <Container>
    <Row>
      <Col xs12>
        <Box position='relative'>
          <Box
            position='absolute'
            left='-77px'
            display={{ xs: 'none', md: 'block' }}
          >
            <Icon icon ='more'/>
          </Box>

          <Subtitle text='
          <span style="color: #999999;">Нашими клиентами являются как&nbsp;</span>
            крупные Банки<span style="color: #999999;"> и </span>Интеграторы<span style="color: #999999;">, так и </span>Компании из реального сектора экономики
          ' />

          <Box
            display='flex'
            justifyContent={{ xs: 'right', md: 'center' }}
            m={{ xs: '50px 0 30px 0', md: '70px 0 30px 0' }}
          >
            <Img src={doc}/>
          </Box>

          <Description center text='
            <p>Сервис по&nbsp;поиску врачей, стремящийся помочь людям оперативно найти хорошего доктора и&nbsp;записаться к&nbsp;нему на&nbsp;приём. Для этого компания создали базу врачей, собирала отзывы у&nbsp;пациентов после приёма и&nbsp;опубликовала их&nbsp;на&nbsp;сайте, на&nbsp;котором предоставляет подробную информацию о&nbsp;специалисте (опыт, квалификация, специализация, расписание), которая формирует его рейтинг.</p>
          '/>

        </Box>
      </Col>


      <Col xs12 md6>
        <Box
          display='flex'
          justifyContent={{ xs: 'right', md: 'center' }}
          m='50px 0 30px'
        >
          <Img src={sber}/>
        </Box>
        <Description center text='
          <p>Российский государственный финансовый конгломерат, крупнейший транснациональный и&nbsp;универсальный банк Российской Федерации&nbsp;&mdash; России, Центральной и&nbsp;Восточной Европы. По&nbsp;итогам 2019 года у&nbsp;Сбербанка 96,2&nbsp;млн активных частных клиентов и&nbsp;2,6&nbsp;млн активных корпоративных клиентов.</p>
        '/>
      </Col>

      <Col xs12 md6>
        <Box
          display='flex'
          justifyContent={{ xs: 'right', md: 'center' }}
          m='50px 0 30px'
        >
          <Img src={mfm}/>
        </Box>
        <Description center text='
          <p>Интегратор, занимающийся построением систем автоматизации <nobr>ИТ-процессов</nobr> на&nbsp;базе передовых программных решений от&nbsp;IBM, SimpleOne, BMC, а&nbsp;также поставки серверного и&nbsp;сетевого оборудования от&nbsp;мировых лидеров HP&nbsp;и&nbsp;Cisco</p>
        '/>
      </Col>

      <Col xs12 md6>

        <Box
          display='flex'
          justifyContent={{ xs: 'right', md: 'center' }}
          m='50px 0 30px'
        >
          <Img src={korus}/>
        </Box>

        <Description center text='
          <p>Разработчик и&nbsp;провайдер облачных сервисов для автоматизации процессов управления цепочками поставок и&nbsp;взаиморасчетов. Клиентами КОРУС Консалтинг СНГ являются почти все торговые сети и&nbsp;их&nbsp;поставщики, международные <nobr>компании-производители</nobr>, логистические и&nbsp;телекоммуникационные операторы, <nobr>ТОП-20</nobr> банков и&nbsp;финансовых организаций РФ, поставщики услуг <acronym title="Жилищно-коммунальное хозяйство" lang="ru">ЖКХ</acronym> и&nbsp;другие компании.</p>
        '/>
      </Col>

      <Col xs12 md6>

        <Box
          display='flex'
          justifyContent={{ xs: 'right', md: 'center' }}
          m='50px 0 30px'
        >
          <Img src={sberlog}/>
        </Box>

        <Description center text='
          <p>Компания экосистемы Сбербанка, логистический оператор федерального масштаба, развивающий собственную складскую инфраструктуру, курьерскую доставку и&nbsp;сеть пунктов выдачи заказов во&nbsp;всех регионах России.
          Компания реализует технологичные логистические решения с&nbsp;едиными стандартами качества сервиса по&nbsp;всей стране и&nbsp;за&nbsp;рубежом для частных и&nbsp;корпоративных клиентов.</p>
        '/>
      </Col>


      <MenuBottom/>

    </Row>
  </Container>
</Scroll>

<Logo />

</>)

};

export default Fragment;
