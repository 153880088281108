import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const Lead = styled.div`
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
  color: #FFFFFF;
  ${device.xs} {
    font-size: 60px;
  }
`;
