import styled from 'styled-components';

export const Img = styled.img`
  max-height: 50px;
  transition-duration: 0.3s;
  max-width: 100%;
  &:hover {
    transform: scale(1.1);
  }
`;
