import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 320px;
  height: 240px;
  background-color: #161616;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  margin-right: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  &:hover {
    background-color: #D02B48;
  }
`;
