import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const Subtitle = styled.div`
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: ${({lh}) => lh ? lh : '44px' };
  color: ${({color}) => color ? color : '#FFFFFF' };
  ${device.xs} {
    font-size: 24px;
    line-height: 30px;
  }
`;
