import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const File = styled.div`
  width: max-content;
  background: #D02B48;
  padding: 20px 25px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
  ${device.xs} {
    width: auto;
    font-size: 12px;
    line-height: 24px;
  }
`;

export const Notice = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
  ${device.xs} {
    width: auto;
    font-size: 12px;
    line-height: 24px;
    margin-top: 20px
  }
`;

export const FileInputStyled = styled.input`
  overflow: hidden;
  position: absolute;
  height: 64px;
  width: 289px;
  opacity: 0;
  cursor: pointer;
`;
