import React from 'react';

import {Tab,Tabs} from "./styled";

const Fragment = ({setTabState,tabState}) => {

  const handleChange = (event, newValue) => {
    console.log('newValue',newValue);
    setTabState(newValue);
  };

  return(
    <>
      <Tabs value={tabState} onChange={handleChange}>
          <Tab
            disableRipple
            value={0}
            index="0"
            label='Клиентам'
          />
          <Tab
            disableRipple
            value={1}
            index="1"
            label='Соискателям'
          />
      </Tabs>
    </>
  )

};

export default Fragment;
