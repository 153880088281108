import React from 'react';

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";



const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1090,
      xl: 1240,
    },
  },
})



const Fragment = ({children}) => {



  return(
    <>
    <MuiThemeProvider theme={theme}>

      {children}

    </MuiThemeProvider>

    </>
  )

};

export default Fragment;
