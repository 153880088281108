import styled from 'styled-components';

import img from '../../../src/img/corner.svg';
import { device } from '../../components/Layout/Grid';


export const WrapperLink = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -20px;
  height: 460px;
  & a {
    position: relative;
    width: max-content;
    margin-bottom: 20px;
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 70px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
    padding: 17px 17px 3px 17px;
    ${device.xs} {
      font-size: 50px;
    }
  }
  & a:hover {
    background: #D02B48;
  }
  & a:before {
    content: '';
  }
  & a:hover:before {
    content: '';
    background: url(${img}) no-repeat center top;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
  }
`;
