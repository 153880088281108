import Scroll from '../../components/Layout/InnerScroll'
import {Container,Row,Col} from '../../components/Layout/Grid';
import React from 'react';

import Icon from '../../components/Icons'
import Progress from '../../components/Progress'
import Lightbox from '../../components/Lightbox'
import Header from "../../components/Header";
import Subtitle from "../../components/Text/Subtitle";
import Description from "../../components/Text/Description";
import Line from "../../components/Line";
import MenuBottom from "../../components/Menu/DesktopBottom";
import Logo from "../../components/Logo";
import {Helmet} from "react-helmet";
import Box from '@material-ui/core/Box';

const Fragment = () => {

  const [scrollTop, setScrollTop] = React.useState(0);


return (<>

<Helmet>
  <title>Мы занимаемся Java, 1С и мобильной разработкой на android и ios</title>
  <meta name="description" content="Мы честно и по нормальным ценам выполняем разработку на java и 1С, а стоимость мобильной разработки на android и ios - всегда в согласованном бюджете" />
</Helmet>

<Header text='Кто мы'/>

<Scroll setScrollTop={setScrollTop}>
  <Container>
    <Row>
      <Col xs12>
        <Box position='relative'>
          <Box
            position='absolute'
            left='-77px'
            display={{ xs: 'none', md: 'block' }}
          >
            <Icon icon ='more'/>
          </Box>
          <Subtitle text='Бизнес интеграция — это российский разработчик программного обеспечения на основе современного стека информационных технологий' />
          <Description text='
            <p>Нашими клиентами являются как крупные Банки и&nbsp;Интеграторы, так и&nbsp;Компании реального сектора экономики, производящих материальные и&nbsp;нематериальные товары и&nbsp;услуги:</p>
            <ul>
                <li>КОРУС КОНСАЛТИНГ СНГ</li>
                <li>ПАО СБЕРБАНК</li>
                <li>МФМ-Интеграция</li>
                <li>СберЛогистика</li>
                <li>ДокДок Территория здоровья</li>
            </ul>
          '/>
        </Box>
        <Box mb={{ xs: '40px', lg: '50px' }}/>

      </Col>

      <Col xs12>
        <Subtitle text='Услуги и продукты' />
      </Col>
      <Col xs12 md6 >
        <Box>
          <Description text='
            Мы предлагаем:
            <ul>
                <li><span style="color: #999999;">разработку </span>высоконагруженных бизнес-приложений<span style="color: #999999;">;</span></li>
                <li><span style="color: #999999;">разработку </span>банковских систем<span style="color: #999999;">;</span></li>
                <li><span style="color: #999999;">разработка </span>дизайна<span style="color: #999999;"> и </span>мобильных приложений<span style="color: #999999;"> для iOS и Android;</span></li>
                <li><span style="color: #999999;">интеграцию с аналитическими и бухгалтерскими системами </span>1С:Предприятие 8<span style="color: #999999;">;</span></li>
                <li><span style="color: #999999;">разработку </span>веб-сервисов<span style="color: #999999;"> для 1С:Предприятие 8;</span></li>
                <li><span style="color: #999999;">интеграцию с системами </span>ЭДО <span style="color: #999999;">(электронного документооборота);</span></li>
                <li><span style="color: #999999;">интеграцию с сайтами на </span>WordPress<span style="color: #999999;">, </span>Joomla<span style="color: #999999;">, </span>Bitrix<span style="color: #999999;">;</span></li>
                <li><span style="color: #999999;">интеграцию с </span>CRM-системами<span style="color: #999999;">.</span></li>
            </ul>
          '/>
        </Box>
      </Col>

      <Col xs12 md6 >
        <Box mr='40px'>
          <Lightbox />
        </Box>
      </Col>

      <Col xs12>
        <Box  m={{xs: '30px 0', md: '40px 0'}}  width='100%'>
          <Line />
        </Box>
      </Col>

      <Col xs12 >
        <Subtitle text='Технологический стек' />
      </Col>

      <Col xs12 md6>
        <Box mt='20px'>
          <Description text='BACKEND' />
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Java 8+' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} maxProgress="90" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Spring, Spring boot Frameworks' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} maxProgress="85" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Apache Kafka / Kafka Streams' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} maxProgress="87" speed="40"/>
        </Box>
      </Col>

      <Col xs12 md6>
        <Box mt='20px'>
          <Description text='FRONTEND' />
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='JavaScript (включая современные стандарты ES6, ES7)' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#5BC0DE' maxProgress="90" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='React & Redux библиотеки)' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#5BC0DE' maxProgress="85" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Typescript' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#5BC0DE' maxProgress="87" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='GraphQL' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#5BC0DE' maxProgress="93" speed="40"/>
        </Box>

        <Box m='20px 0 20px 0'>
          <Description text='Enzyme & Jest' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#5BC0DE' maxProgress="87" speed="40"/>
        </Box>
      </Col>

      <Col xs12>
        <Box  m={{xs: '30px 0', md: '40px 0'}}  width='100%'>
          <Line />
        </Box>
      </Col>

      <Col xs12 md6>
        <Subtitle text='Базы данных' />

        <Box m='20px 0 30px 0'>
          <Description text='ORACLE SQL' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#818A91' maxProgress="90" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Hibernate' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#818A91' maxProgress="85" speed="40"/>
        </Box>

        <Box m='20px 0 55px 0'>
          <Description text='Liquibase' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#818A91' maxProgress="90" speed="40"/>
        </Box>

        <Subtitle text='1С:Предприятие 8' />

        <Box m='20px 0 30px 0'>
          <Description text='Внедрение и доработка 1С:ERP 2, БП, ЗУП, ДО' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#F0AD4E' maxProgress="90" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Интеграция с 1С, разработка веб-сервисов' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#F0AD4E' maxProgress="90" speed="40"/>
        </Box>

      </Col>

      <Col xs12 md6>
        <Subtitle text='Devops и вспомогательные технологии' />

        <Box m='20px 0 30px 0'>
          <Description text='Jenkins' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#D9534F' maxProgress="87" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Gradle' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#D9534F' maxProgress="90" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Git' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#D9534F' maxProgress="93" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Maven' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#D9534F' maxProgress="90" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='Webpack' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#D9534F' maxProgress="87" speed="40"/>
        </Box>

        <Box m='20px 0 30px 0'>
          <Description text='ELK - Elasticsearch, Logstash, Kibana' />
          <Box mb='5px' />
          <Progress scrollTop={scrollTop} bgcolor='#D9534F' maxProgress="90" speed="40"/>
        </Box>


      </Col>

      <MenuBottom/>


    </Row>
  </Container>
</Scroll>

<Logo />

</>)

};

export default Fragment;
