import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const Wrapper = styled.div`
position: relative;
  max-width: 700px;
  height: auto;
  background-color: #1F1F1F;
  padding: 0 100px 80px 100px;
  overflow: auto;
  word-wrap: break-word;
  ${device.xs} {
    padding: 0 40px 80px 40px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #000000;
  }
  &::-webkit-scrollbar-thumb {
    margin-top: 20px;
    background-color: #424242;
  }
  svg {
    ${device.xs} {
      height: 35px;
    }
  }
`;
