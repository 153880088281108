import styled from 'styled-components';

export const FirstWord = styled.div`
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;
  color: #D02B48;
`;
