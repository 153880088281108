import styled, {keyframes} from 'styled-components/macro';

const move = keyframes`
  0% {
    transform: rotate(0deg) translateX(900px) rotate(0deg);
    opacity: 0;
  }

  5% {
    transform: rotate(-90deg) translateX(900px) rotate(90deg);
    opacity: 0;
  }

  8% {
    opacity: 0;
  }
  17%,28% {
    transform: rotate(-180deg) translateX(900px) rotate(180deg);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    transform: rotate(-270deg) translateX(900px) rotate(270deg);
    opacity: 0;
  }
  100% {
    transform: rotate(-360deg) translateX(900px) rotate(360deg);
    opacity: 0;
  }
`;

const SvgImage = styled.img`
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 300px;
  /* width: 600px; */
  height: auto;
  animation: ${move} 10s cubic-bezier(0.42, 0, 0, 1.03) infinite;
`;

export const Svg1 = styled(SvgImage)`

`;

export const Svg2 = styled(SvgImage)`
  animation-delay: 2.5s;
`;

export const Svg3 = styled(SvgImage)`
  animation-delay: 5s;
`;

export const Svg4 = styled(SvgImage)`
  animation-delay: 7.5s;
`;

export const Svg5 = styled(SvgImage)`
  animation-delay: 7.5s;
`;

const move2 = keyframes`

  0%,5% {
    transform: rotate(-180deg) translateX(900px) rotate(180deg);
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  40% {
    transform: rotate(-270deg) translateX(900px) rotate(270deg);
    opacity: 0;
  }
  60% {
    transform: rotate(-360deg) translateX(900px) rotate(360deg);
    opacity: 0;
  }
  65% {
    transform: rotate(0deg) translateX(900px) rotate(0deg);
    opacity: 0;
  }
  85% {
    transform: rotate(-90deg) translateX(900px) rotate(90deg);
  }
  100% {
    opacity: 0;
  }
`;

export const Svg6 = styled.img`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 300px;
  /* width: 600px; */
  height: auto;
  animation: ${move2} 7s ease-in-out;
`;
