import React from 'react';

import {Submit} from "./styled";



const Fragment = ({handler=()=>true}) => {

  return(
  <>

    <Submit onClick={handler}>Отправить</Submit>

  </>
  )

};

export default Fragment;
