const Icon = ({width, height}) => {

  return (
    <svg
      width={ width ? width : "45"}
      height={ height ? height : "45"}
      viewBox="0 0 45 45"
    >
      <rect x="9.15527e-05" y="42.4515" width="60" height="3" transform="rotate(-45 9.15527e-05 42.4515)" fill="#D02B48"/>
      <rect width="60" height="3" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 44.5477 42.4515)" fill="#D02B48"/>

    </svg>
  );
};
export default Icon;
