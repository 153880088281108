import React from 'react';
import Phone from '../../Phone'
import MuiThemeProvider from '../MuiThemeProvider'

import {
  Scroll
      } from './styled';

const Fragment = ({children,setScrollTop = ()=>true}) => {

  const handler = (e) => {
    setScrollTop(e.target.scrollTop)
  }

  return(

  <MuiThemeProvider>
    <Scroll onScroll={(e)=>handler(e)}>
        {children}
      <Phone/>
    </Scroll>
  </MuiThemeProvider>

  )

};

export default Fragment;
