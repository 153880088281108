import React from 'react';

import Icon from '../../Icons'
import Line from "../../Line";
import Title from "../../Text/Title";
import Subtitle from "../../Text/Subtitle";
import Description from "../../Text/Description";
import Form from "../../Form/Wrapper";

import Box from '@material-ui/core/Box';

import {Wrapper} from "./styled";

const Fragment = ({vacancy,city,skills,requirements,about,
  softSkills,conditions,onClose=()=>true}) => {



  const getRequirements = () => {

    return <>{requirements.split("\n").map( it => <><Description text={it} /> <Box mb='3px'/></>)}</>

    /*return <><Description text={experience} />
    <Box mb='3px'/>
    <Description text={time} /></>*/
  }

  const getAbout = () => {

    let text = ''
    about.split("\n").map( it => {text += '<p>'+it+'</p>'});
    return text;
  }

  const getText = (arr) => {

    let text = '<ul>'
    arr.split("\n").map( it => {text += '<li>'+it+'</li>'});
    text += '</ul>'
    return text;
  }

  const reloadvak = () => {
    window.location.reload();
  }



  return(
    <>
      <Wrapper>
        <Box
          position='relative'
          top={{ xs: '0px', md: '52px' }}
          display='flex'
          justifyContent='space-between'
          maxWidth='1020px'
          m={{ xs: '30px 0', md: '0 -60px' }}
        >
          <Icon icon='slash'/>

          <div style={{cursor: 'pointer'}}>
            <Box onClick={onClose}>
              <Icon icon ='close' />
            </Box>
          </div>

        </Box>
        <Title text={vacancy} />

        <Box mb='20px'/>

        <Description text={city} />

        <Box m='30px 0'>
          <Line />
        </Box>

        {getRequirements()}


        <Box m='30px 0'>
          <Line />
        </Box>

        <Description text={getAbout()} />

        <Box mb='40px'/>
        <Subtitle color='#D02B48' text='Отлично, если у вас есть:' />
        <Description text={getText(skills)} />

        <Box mb='40px'/>

        <Subtitle color='#D02B48' text='Будет здорово, если Вы:' />
        <Description text={getText(softSkills)} />

        <Box mb='40px'/>

        <Subtitle color='#D02B48' text='Условия:' />
        <Description text={getText(conditions)} />

        <Box mb='40px'/>

        <Subtitle color='#D02B48' text='Откликнуться на вакансию:' />

        <Box m='30px 0'>
          <Line />
        </Box>

        <Form reloadvak={reloadvak}/>

      </Wrapper>
    </>
  )

};

export default Fragment;
