import styled from 'styled-components';
import { device } from '../Layout/Grid';

export const Wrapper = styled.div`
  z-index: 20;
  background-color: #1F1F1F;
  padding-top: 40px;
  ${device.xs} {
    padding-top: 30px;
  }
  svg {
    ${device.xs} {
      width: 35px;
      height: 35px;
    }
  }
`;



export const Line = styled.div`
  background-color: #D02B48;
  height: 3px;
  width: 100%;
`;
