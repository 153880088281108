import styled from 'styled-components';
import { device } from '../Layout/Grid';

export const BoxWrapper = styled.div`
  float: right;
  width: 60px;
  svg {
    ${device.xs} {
      width: 50px;
      height: 50px;
    }
  }
`;
