import React from 'react';
import Icon from '../Icons'

import Box from '@material-ui/core/Box';


const Fragment = ({text}) => {

  return(
    <>
    <Box
      display={{ xs: 'none', md: 'block' }}
      position='relative'
      maxWidth='1200px'
      m='0 auto'
    >
      <Box
        position='fixed'
        bottom='84px'
      >
        <Icon icon='sublogo'/>
      </Box>
    </Box>
    </>
  )

};

export default Fragment;
