import React from 'react';
import Icon from '../Icons'
import Description from '../Text/Description'
import Box from '@material-ui/core/Box';



const Fragment = ({text, lh}) => {

  return(
    <>
      <Box
        position='relative'
        bottom='-126px'
      >
        <Box
          display={{xs: 'none', md: 'flex'}}
          alignItems='center'
        >
          <Icon icon ='arrow'/>
          <Box mr='10px'/>
          <Description text='Создадим лучшую версию вашего бизнеса' />
        </Box>
      </Box>


    </>
  )

};

export default Fragment;
