import styled from 'styled-components';
import TabMaterial from '@material-ui/core/Tab';
import TabsMaterial from '@material-ui/core/Tabs';
import { device } from '../Layout/Grid';

export const Tabs = styled(TabsMaterial)`
  && {
    min-height: 55px;
  }
  .MuiTabs-flexContainer {
    padding-bottom: 4px;
    border-bottom: 3px solid #666;
    width: max-content;
  }
  .MuiTabs-indicator {
    height: 3px;
    background-color: #D02B48;
  }

`;


export const Tab = styled(TabMaterial)`
  && {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    text-transform: none;
    ${device.xs} {
      font-size: 18px;
    }
  }
`;
