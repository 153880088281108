import React from 'react';

import Icon from '../Icons'
import Box from '@material-ui/core/Box';

import {BoxWrapper} from "./styled";

const Fragment = () => {

  return(
  <>
    <Box
      position='relative'
      maxWidth='1240px'
    >
      <BoxWrapper>

        <Box
          position='fixed'
          bottom={{ xs: '20px', md: '40px' }}
        >
          <a href="tel:+79000000000">
            <Icon icon='phone'/>
          </a>
        </Box>
      </BoxWrapper>
    </Box>
  </>
  )

};

export default Fragment;
