import Scroll from '../../components/Layout/InnerScroll'
import {Container,Row,Col} from '../../components/Layout/Grid';

import Icon from '../../components/Icons'
import Header from "../../components/Header";
import Subtitle from "../../components/Text/Subtitle";
import Description from "../../components/Text/Description";
import FirstWord from "../../components/Text/FirstWord";
import MenuBottom from "../../components/Menu/DesktopBottom";
import Logo from "../../components/Logo";
import Map from "../../components/Map";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import Box from '@material-ui/core/Box';

const Fragment = () => {

return (<>
<Helmet>
  <title>Контакты - Бизнес Интеграция</title>
  <meta property="og:description" content="Напишите нам EMAIL info@businessintegration.ru Хотите предложить сотрудничество? Пришлите своё предложение.&nbsp; HR hr@businessintegration.ru Выберите вакансию в своем городе, отправьте отклик или напишите нам. Адреса проектных офисов г. Москва г. Санкт-Петербург г. Краснодар г. Новосибирск г. Екатеринбург г. Тула ООО «Бизнес Интеграция» Юридический адрес 350049, г. Краснодар, ул. Красных партизан, дом 226, эт.2, офис 207 ИНН&nbsp;2308267610 КПП&nbsp;230801001" />
</Helmet>

<Header text='Контакты'/>

<Scroll>
  <Container>
    <Row>
      <Col xs12 >
        <Box position='relative'>
          <Box
            position='absolute'
            left='-77px'
            display={{ xs: 'none', md: 'block' }}
          >
            <Icon icon ='more'/>
          </Box>
          <Subtitle text='Напишите нам' />
        </Box>
        <Box mb={{ xs: '30px', lg: '60px' }}/>

      </Col>

      <Col xs12 md6>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', lg: 'row' }}
          mb={{ xs: '30px', lg: '40px' }}
        >
          <Box m='4px 38px 0 0'>
            <FirstWord text='EMAIL'/>
          </Box>
          <Box maxWidth='400px'>
            <Description text='
              <a href="mailto:info@businessintegration.ru">info@businessintegration.ru</a> Хотите предложить сотрудничество? Пришлите своё предложение.
            '/>
          </Box>
        </Box>

      </Col>

      <Col xs12 md6>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', lg: 'row' }}
          mb={{ xs: '30px', lg: '0' }}
        >
          <Box m='4px 38px 0 0'>
            <FirstWord text='HR'/>
          </Box>
          <Box maxWidth='400px'>
            <Description text='
            <a href="mailto:info@businessintegration.ru">hr@businessintegration.ru</a> Выберите <a href="https://businessintegration.ru/vakansii/">вакансию</a> в&nbsp;своем городе, отправьте отклик или напишите нам.
            '/>
          </Box>
        </Box>
      </Col>

      <Col xs12>
        <Box my={{ xs: '30px', lg: '50px' }}>
          <Subtitle text='Адреса проектных офисов' />
        </Box>
      </Col>



      <Col xs12 md6 >
        <Description text='г. Москва'/>
        <Map
          name="Москва, Кутузовский 32"
          link="https://maps.google.com/maps?q=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0%2C%20%D0%9A%D1%83%D1%82%D1%83%D0%B7%D0%BE%D0%B2%D1%81%D0%BA%D0%B8%D0%B9%2032&amp;t=m&amp;z=14&amp;output=embed&amp;iwloc=near"
        />
        <Map
          name="Москва, ул. Ленинская Слобода, 26, стр. 6"
          link="https://maps.google.com/maps?q=%20%D1%83%D0%BB.%20%D0%9B%D0%B5%D0%BD%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F%20%D0%A1%D0%BB%D0%BE%D0%B1%D0%BE%D0%B4%D0%B0%2C%2026%2C%20%D1%81%D1%82%D1%80.%206&t=m&z=14&output=embed&iwloc=near"
        />
        <Map
          name="Москва, Новоданиловская наб., 10, "
          link="https://maps.google.com/maps?q=%D0%9D%D0%BE%D0%B2%D0%BE%D0%B4%D0%B0%D0%BD%D0%B8%D0%BB%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BD%D0%B0%D0%B1.%2C%2010%2C%20%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0&t=m&z=13&output=embed&iwloc=near"
        />

        <Description text='г. Санкт-Петербург'/>
        <Map
          name="Москва, Кутузовский 32"
          link="https://maps.google.com/maps?q=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3%2C%20%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%BE%D0%B9%20%D0%A1%D0%B0%D0%BC%D0%BF%D1%81%D0%BE%D0%BD%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%B8%D0%B9%20%D0%BF%D1%80.%2C%20%D0%B4.%2068&t=m&z=13&output=embed&iwloc=near"
        />

      </Col>

      <Col xs12 md6 >
        <Description text='г. Краснодар'/>
        <Map
          name="Краснодар, ул. Красных партизан, дом 226"
          link="https://maps.google.com/maps?q=%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%BE%D0%B4%D0%B0%D1%80%2C%20%D1%83%D0%BB.%20%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D1%8B%D1%85%20%D0%BF%D0%B0%D1%80%D1%82%D0%B8%D0%B7%D0%B0%D0%BD%2C%20%D0%B4%D0%BE%D0%BC%20226&t=m&z=16&output=embed&iwloc=near"
        />

        <Description text='г. Новосибирск'/>
        <Map
          name="г. Новосибирск, ул. Октябрьская, д. 52"
          link="https://maps.google.com/maps?q=%D0%B3.%20%D0%9D%D0%BE%D0%B2%D0%BE%D1%81%D0%B8%D0%B1%D0%B8%D1%80%D1%81%D0%BA%2C%20%D1%83%D0%BB.%20%D0%9E%D0%BA%D1%82%D1%8F%D0%B1%D1%80%D1%8C%D1%81%D0%BA%D0%B0%D1%8F%2C%20%D0%B4.%2052&t=m&z=14&output=embed&iwloc=near"
          height="276px"
        />

        <Description text='г. Екатеринбург'/>
        <Map
          name="г. Новосибирск, ул. Октябрьская, д. 52"
          link="https://maps.google.com/maps?q=%D0%B3.%20%D0%95%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%BD%D0%B1%D1%83%D1%80%D0%B3%2C%20%D0%BF%D1%80.%20%D0%9B%D0%B5%D0%BD%D0%B8%D0%BD%D0%B0%2C%20%D0%B4.%2025&t=m&z=14&output=embed&iwloc=near"
          height="276px"
        />

        <Description text='г. Тула'/>
        <Map
          name="г. Новосибирск, ул. Октябрьская, д. 52"
          link="https://maps.google.com/maps?q=%D0%B3.%20%D0%A2%D1%83%D0%BB%D0%B0%2C%20%D0%BF%D1%80.%20%D0%9B%D0%B5%D0%BD%D0%B8%D0%BD%D0%B0%2C%20%D0%B4.%2050%20%D0%93&t=m&z=13&output=embed&iwloc=near"
        />
      </Col>


      <Col xs12>
        <Box mb='80px' />
      </Col>


      <Footer />

      <MenuBottom/>

    </Row>
  </Container>
</Scroll>

<Logo />

</>)

};

export default Fragment;
