import React from 'react';

import {Line} from "./styled";

const Fragment = () => {

  return(
    <>
      <Line/>
    </>
  )

};

export default Fragment;
