import React from 'react';
import {Col} from '../../Layout/Grid';
import Description from "../../Text/Description";
import {
  Link
} from "react-router-dom";
import Box from '@material-ui/core/Box';

import {WrapperLink} from "./styled";


const Fragment = () => {

  return(
    <>
    <Col xs12 md6 >
      <Box mt={{ xs: '20px', lg: '60px' }}>
        <Box top='20px' position='relative'>
          <WrapperLink>
            <Link to="/who">Кто мы</Link>
            <Link to="/vacancies">Вакансии</Link>
            <Link to="/contacts">Контакты</Link>
          </WrapperLink>
        </Box>
      </Box>
    </Col>

    <Col xs12 md6>
      <Box mt={{ xs: '0px', lg: '60px' }}>
        <Box top='20px' position='relative'>
          <Description text='
            <span style="color: #999999;">© 2020 «Бизнес Интеграция»</span>
          '/>
        </Box>
      </Box>
    </Col>
    </>
  )

};

export default Fragment;
