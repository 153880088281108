import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const Scroll = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  height: calc(100vh - 285px);
  overflow: hidden auto;
  ${device.xs} {
    height: calc(100vh);
    height: calc(100vh - 50px);
    padding-top: 30px;
    padding-bottom: 20px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #000000;
    margin: 110px 0;
    ${device.xs} {
      margin: 0;
    }
  }

  &::-webkit-scrollbar-thumb {
    margin-top: 20px;
    background-color: #424242;
  }

`;
