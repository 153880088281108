import styled from 'styled-components';
import { device } from '../../Layout/Grid';

export const WrapperLink = styled.div`
  display: flex;
  flex-direction: row;
  ${device.xs} {
    flex-direction: column;
  }
  a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-right: 30px;
    padding-bottom: 6px;
    border-bottom: 1px solid #555555;
    width: max-content;
    ${device.xs} {
      margin-bottom: 23px;
    }
  }
`;
