import React from 'react';

import Subtitle from "../../Text/Subtitle";
import Description from "../../Text/Description";

import {Wrapper} from "./styled";

const Fragment = ({vacancy, city, lh, handler=()=>true}) => {



  return(
    <>
      <Wrapper onClick={handler}>
        <Subtitle
          text={vacancy}
          lh='32px'
        />
        <Description
          text={city}
          opacity='.6'
        />
      </Wrapper>
    </>
  )

};

export default Fragment;
