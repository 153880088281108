import styled from 'styled-components';

export const container = {
  main:   `1200px`,
  inner:  `1040px`,
};

export const device = {
  xs: `@media (max-width: 959px)`,
  md: `@media (min-width: 960px)`,
};

export const col = {
  xs12: `${device.xs} {
        width: 100%;
      }`,
  md6: `${device.md} {
        width: calc(50% - 60px);
      }`,
  md12: `${device.md} {
        width: 100%;
      }`
};

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: ${({main}) => main ? container.main : container.inner};
  ${device.xs} {
    padding-bottom: ${({pb}) => pb ? pb : '190px' };
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-right: -30px;
`;

export const Col = styled.div`
  box-sizing: border-box;
  word-wrap: break-word;
  margin: 0 30px;
  ${({md6}) => md6 ? col.md6 : col.md12};
  ${({xs12}) => xs12 ? col.xs12 : ''};
`;
