import styled from 'styled-components';
import { device } from '../../Layout/Grid';


export const FormItem = styled.div`
  margin-bottom: 20px;
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    ${device.xs} {
      font-size: 12px;
      line-height: 24px;
    }
  }
  input {
    margin-top: 8px;
    background: #1F1F1F;
    border: 2px solid #353535;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 18px 10px 15px;
    outline: none;
    &:focus {
      color: #FFFFFF
    }
    &[type="text"]{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
    }
    ${device.xs} {
      font-size: 12px;
      line-height: 24px;
    }
  }
`;
