import React from 'react';
import Box from '@material-ui/core/Box';

import {Iframe} from "./styled";

const Fragment = ({name,link,height='300px'}) => {

  return(
    <>
    <Box>
      <Iframe
        width="100%"
        height={height}
        frameBorder="0"
        scrolling="no"
        aria-label={name}
        src={link}
      >
      </Iframe>
    </Box>
    </>
  )

};

export default Fragment;
