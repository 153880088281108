import React from 'react';

import {FormItem} from "./styled";



const Fragment = ({label,name}) => {

  return(

  <>

    <FormItem>
      <label>{label}</label>
      <select name={name}>
        <option value="Не выбрано">Выберите город</option>
        <option value="Екатеринбург">Екатеринбург</option>
        <option value="Краснодар">Краснодар</option>
        <option value="Москва">Москва</option>
        <option value="Новосибирск">Новосибирск</option>
        <option value="Санкт-Петербург">Санкт-Петербург</option>
        <option value="Тула">Тула</option>
      </select>
    </FormItem>

  </>
  )

};

export default Fragment;
