import Scroll from '../../components/Layout/InnerScroll'
import {Container,Row,Col} from '../../components/Layout/Grid';
import axios from 'axios';
import Icon from '../../components/Icons'
import Header from "../../components/Header";
import MenuBottom from "../../components/Menu/DesktopBottom";
import Logo from "../../components/Logo";
import Card from "../../components/Vacancy/Card";
import Popup from "../../components/Vacancy/Popup";

import Box from '@material-ui/core/Box';
import {Helmet} from "react-helmet";

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Fragment = () => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(false);
  const [current, setCurrent] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeCurrent = (it) => {
    setCurrent(it)
    handleOpen();
  }

  const getInitialDate = async () => {

    let dataServer = await axios.get(`/api/list`);
    setData(dataServer.data);
    console.log('dataServer',dataServer);
  }

  React.useEffect(()=>{

    getInitialDate();
  },[]);


return (<>

<Helmet>
  <title>Свежие вакансии работодателя, работа в Москве, СПб, java вакансии</title>
  <meta name="description" content="Свежие вакансии, работа в Москве, Санкт-Петербурге, Краснодаре. Java вакансии, вакансия backend разработчиков, middle и senior программисты java, javascript" />
</Helmet>

<Header text='Вакансии'/>

<Scroll>
  <Container>
    <Row>
      <Col xs12 md12>

        <Box position='relative'>

          <Box
            position='absolute'
            left='-77px'
            display={{ xs: 'none', md: 'block' }}
          >
            <Icon icon ='more'/>
          </Box>
        </Box>

        <Box
          display='flex'
          justifyContent={{ xs: 'center', md: 'flex-start'}}
          flexWrap="wrap"
        >

          {data && data.map((it,ind) =>
            <Card
            handler={() => changeCurrent(it)}
            vacancy={it.text[0]}
            city={it.text[1]}
          />
          )}

        </Box>

      </Col>

      <MenuBottom/>

      </Row>
    </Container>
</Scroll>

<Logo />

{current && <Modal
  aria-labelledby="transition-modal-title"
  aria-describedby="transition-modal-description"
  className={classes.modal}
  open={open}
  onClose={handleClose}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 500,
  }}
>
  <Fade in={open}>

    <Popup
      onClose={handleClose}
      vacancy={current.text[0]}
      city={current.text[1]}
      requirements={current.text[2]}
      about={current.text[3]}
      skills={current.text[4]}
      softSkills={current.text[5]}
      conditions={current.text[6]}
    />

  </Fade>
</Modal>}


</>)

};

export default Fragment;
