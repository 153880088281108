import styled from 'styled-components';
import { device } from '../../Layout/Grid';


export const Scroll = styled.div`
  /* max-width: 1280px; */
  margin: 0 auto;
  padding-top: 75px;
  height: calc(100vh - 100px);
  height: calc(100vh);
  overflow: hidden auto;
  ${device.xs} {
    padding-top: 35px;
    height: calc(100vh - 100px);
  }
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #000000;
    /* margin: 50px 0; */
  }

  &::-webkit-scrollbar-thumb {
    margin-top: 20px;
    background-color: #424242;
  }

`;
