import React from 'react';

import {Title} from "./styled";

const Fragment = ({text}) => {

  return(
    <>
      <Title
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </>
  )

};

export default Fragment;
